const tableHeaderFields = {
  PRODUCTEVALUATIONLIST: [
    { id: "ExecutionRunId", label: "Run Id" },
    {
      id: "ProductEvaluationExecutionRunName",
      label: "Product Evaluation Run Name",
    },
    { id: "StartTime", label: "Run Start Date" },
    { id: "EndTime", label: "Run End Date" },
    { id: "Status", label: "Run Status" },
    { id: "CreatedBy", label: "Run Created By" },
    { id: "ModifiedBy", label: "Run Modified By" },
    { id: "Action", label: "Action" },
  ],
  PRODUCT_EVALUATION_RUN_INPUT_PROVIDER: [
    {
      key: "ExecutionDefinitionName",
      value: "Name of this Product Evaluation Run",
    },
    { key: "SelectedDosageForms", value: "Preferred Dosage Form(s)" },
    {
      key: "FDAOffPatentFreshDataDownloadBl",
      value:
        "Fetch New Dataset from FDA Off-Patent Drugs List?\n(If not, most recent pull will be used)",
    }, // "NO"
    {
      key: "OrangeBookMaxRXCount",
      value: "Orange Book - Max Number of Actives",
    },
    {
      key: "OrangeBookFreshDataDownloadBl",
      value: "Fetch new Orange Book\n(If not, most recent pull will be used)",
    }, // "Yes"
    { key: "AnalysourceMaxActiveCount", value: "FDB - Max Number of Actives" },
    /*{
      key: "TrademarkIgnoreBrandList",
      value: "Any Companies to Exclude from Trademark Search?",
    },*/
    {
      key: "ExecutionRunId",
      value: "Run Id",
    },
  ],
  TRADEMARK_RESULTS_COLUMNS: [
    { id: "IngredientName", label: "Ingredient Name" },
    { id: "DosageForm", label: "Dosage Form" },
    { id: "IngredientDataSourceName", label: "Ingredient Data Source Name" },
    { id: "Strength", label: "Strength" }, // new
    // { id: "Strength", label: "Strength Value" }, // this field could be require in future
    { id: "LabellerName", label: "Labeller Name" },
    { id: "BrandName", label: "Brand Name" },
    { id: "DeadCount", label: "Dead Count" },
    { id: "LiveCount", label: "Live Count" },
    { id: "NoRecordBl", label: "No Record Found" },
    // { id: "NDC", label: "Strength Unit of Measure" }, // this field could be require in future
  ],
  FDB_RESULTS_COLUMNS: [
    { id: "IngredientName", label: "Ingredient Name" },
    { id: "DosageForm", label: "Dosage Form" },
    { id: "IngredientDataSourceName", label: "Ingredient Data Source Name" },
    { id: "Strength", label: "Strength" }, // new
    // { id: "Strength", label: "Strength Value" },// this field could be require in future
    { id: "LabellerName", label: "Labeller Name" },
    { id: "BrandName", label: "Brand Name" },
    // { id: "NDC", label: "Strength Unit of Measure" },// this field could be require in future
    { id: "ActiveInSourceBl", label: "Active" },
  ],
  ORANGE_BOOK_RESULTS_COLUMNS: [
    { id: "IngredientName", label: "Ingredient Name" },
    { id: "DosageForm", label: "Dosage Form" },
    { id: "IngredientDataSourceName", label: "Ingredient Data Source Name" },
    { id: "RXCount", label: "Number Of Actives" },
  ],
  FDA_OFF_PATENT_DRUGS_LIST_RESULTS_COLUMNS: [
    { id: "IngredientName", label: "Ingredient Name" },
    { id: "DosageForm", label: "Dosage Form" },
    { id: "IngredientDataSourceName", label: "Ingredient Data Source Name" },
  ],
  FINAL_RESULT_RESULTS_COLUMNS: [
    { id: "IngredientName", label: "Ingredient Name" },
    { id: "DosageForm", label: "Dosage Form" },
    { id: "Strength", label: "Strength" }, // new
    { id: "BrandName", label: "Brand Name" },
    { id: "LabellerNames", label: "Labeller Names" }, // there LabellerNames
  ],
};
export { tableHeaderFields };
