import { Theme } from "@mui/material/styles";
import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import { colors } from "../../../config/theme";

export const buttonStyles = {
  margin: "auto",
  color: colors?.fullWhite,
  fontWeight: 700,
  fontSize: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
  },
  lineHeight: {
    xs: "17px",
    sm: "19px",
    md: "21px",
    lg: "23px",
    xl: "28px",
  },
  textTransform: "capitalize",
  backgroundColor: colors?.purple,
  borderRadius: "10px",
  padding: {
    xs: "2px 17px",
    sm: "4px 19px",
    md: "6px 21px",
    lg: "8px 23px",
    xl: "10px 25px",
  },
  fontFamily: "Roboto",
  border: "1px solid #176893",
  "&:hover": {
    backgroundColor: colors?.purpleLight,
  },
  "&.Mui-disabled": {
    background: colors?.grey,
    color: colors?.blackLight,
  },
};

export const createRunStyles = {
  mainBox: { width: "100%", height: "100%" },
  mainGridContainer: { width: "100%", height: "100%" },
  titleTagStyle: {
    ml: 6,
    mt: 6,
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: {
      xs: "16px",
      sm: "17px",
      md: "19px",
      lg: "21px",
      xl: "24px",
    },
    lineHeight: {
      xs: "21.13px",
      sm: "23.13px",
      md: "25.13px",
      lg: "27.13px",
      xl: "28.13px",
    },
  },
  formBoxContainer: {
    p: 4,
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  mainContainer: {
    height: "100%",
    overflowY: "auto",
    padding: "0px",
  } as React.CSSProperties,
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    pl: 6,
    pr: 6,
    mt: 2,
    mb: 2,
  },
  inputFieldStyle: {
    fontSize: {
      xs: "10px",
      sm: "11px",
      md: "14px",
      lg: "17px",
      xl: "20px",
    },
    fontWeight: "400",
    fontFamily: "Inter",
    lineHeight: {
      xs: "10px",
      sm: "15px",
      md: "18px",
      lg: "21px",
      xl: "24.2px",
    },
  },
  inputStyle: {
    width: "100%", // Full width of the parent container
    margin: "0", // No margin to ensure alignment with label
    padding: "0", // No padding to ensure alignment with label
  },
  buttonContainer: {
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
    gridTemplateColumns: "auto auto",
  },
  buttonStyle: {
    margin: "auto",
    color: colors?.fullWhite,
    textTransform: "capitalize",
    backgroundColor: colors?.purple,
    borderRadius: "10px",
    fontFamily: "Roboto",
    fontWeight: "700",
    fontSize: {
      xs: "14px",
      sm: "15px",
      md: "17px",
      lg: "19px",
      xl: "20px",
    },
    lineHeight: {
      xs: "21px",
      sm: "21px",
      md: "24px",
      lg: "26px",
      xl: "28px",
    },
    padding: "10px 25px",
    border: "1px solid #000000",
    "&:hover": {
      backgroundColor: colors?.purpleLight,
    },
    "&.Mui-disabled": {
      background: colors?.grey,
      color: colors?.blackLight,
    },
  },
  selecterHelpTextStyle: {
    marginLeft: "12px",
    fontSize: {
      xs: "12px",
      sm: "14px",
      md: "16px",
      lg: "19px",
      xl: "20px",
    },
  },
  helpTextStyle: {
    fontSize: {
      xs: "6px",
      sm: "7px",
      md: "10px",
      lg: "13px",
      xl: "16px",
    },
  },
  selectText: {
    fontSize: {
      xs: "10px",
      sm: "11px",
      md: "14px",
      lg: "17px",
      xl: "20px",
    },
  },
  dropdownFormControlStyle: { width: "100%" },
  selectedListContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: 0.5,
  },
  menuTitleLabel: { fontWeight: "500", fontFamily: "Roboto" },
  pageFormControlStyle: {
    ml: 5,
    mr: 5,
  },
  checkBoxlabel: {
    fontWeight: "500",
    fontFamily: "Roboto",
  },
  chipStyle: { margin: "2px" },
  formBox: {
    p: 4,
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  selectedItemListStyle: {
    display: "flex",
    flexWrap: "wrap",
    gap: 0.5,
  },
};

export function getStyles(
  name: any,
  selectedIds: readonly number[],
  theme: Theme
) {
  return {
    fontWeight:
      selectedIds.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const StyledTextField = styled(TextField)({
  ".MuiInputBase-root": {
    display: "flex",
    flexWrap: "wrap",
  },
  ".MuiInputBase-input": {
    flex: 1,
    minWidth: "120px",
    display: "none",
  },
});
